.life-container {
    padding: 10px;
    height: 97vh;
    text-align: center;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.life-container > h2 {
    height: 30px;
    
}

.life-subcontainer {
    display: flex;
    width: 100%;
    overflow-y: auto;
    justify-content: center;
    padding: 10px;
    font-size: 22px;
    line-height: 40px;
    border: 1px solid rgba(78, 76, 76, 0.322);
}

.life-text-container {
    padding: 20px;
    white-space: pre-line;
    text-align: justify;
    
}

/*
The css for the photos inside the carousel is in CenterPanel.css
 */