.header-inner {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    background-color: white;
    border: none;
}

.header-inner:hover {
    cursor: pointer;
}

@media (max-width: 1400px) {
    .logo {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        margin: 5px;
    }
    
    .header-name {
        font-size: 12px;
        margin-bottom: 0px;
    }
    
    .header-title {
        font-size: 10px;
    }

}

@media (min-width: 1401px) {
    .logo {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin: 10px;
    }
    
    .header-name {
        font-size: 22px;
        margin-bottom: 0px;
    }
    
    .header-title {
        font-size: 18px;
    }
}
