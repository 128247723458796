


.splash {
    width: 100%;
    height: 90vh;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
   
    margin-top: 40px;
}

.splash-image {
    width: 75%;
    margin: 20px;
    border-radius: 10px;
}