.left-panel-inner {
    display: flex;

}



.left-panel-arrow {
    width: 20px;
    height: calc(100vh - 20px);
    display: flex;
    align-items: center;
    background-color: rgb(216, 211, 211);
    margin-right: 0px;
}


@media (max-width: 1400px) {
    .left-panel-content {
      width: 115px;
    }
    
  }
  
  
  @media (min-width: 1401px) and (max-width: 4000px) {
    .left-panel-content {
        width: 280px;
      }
      
  }
  
  .left-panel-inner-closed {
    width: 20px;
  }