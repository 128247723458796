.portfolio-container {
    padding: 10px;
    padding-right: 30px;
    height: 100%;
    overflow-y: auto;
    
}

.portfolio-button {
    width: 100%;

}

.portfolio-button:hover {
    cursor: pointer;
}

.image-column {
    display: flex;
    justify-content: center;
}

.portfolio-description p {
    text-align: justify;
    font-size: 18px;
}