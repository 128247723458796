.skills-container {
    padding: 10px;
    text-align: center;
}

.skills-container h2 {
    height: 30px;
    
}

.skill-subcontainer {
    display: flex;
    width: 100%;
    height: calc(98vh - 70px);
    overflow-y: auto;
    justify-content: center;
    padding: 10px;
}
