.carousel {
    position: relative;
    height: calc(100vh - 10px);
    padding: 20px;
    overflow-y: auto;
}

.horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.horizontalPager {
    position: absolute;
    bottom: 20px;
    left: 50%;
}

.verticalPager {
    position: absolute;
    bottom: 50%;
    right: 30px;
}

.carousel-component-container {
    margin: 20px;
}