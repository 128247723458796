.life-photos {
    width: 25vw;
}


@media (max-width: 768px) {
    .life-photos {
        width: 100%;
        height: 100%;
        max-width: 45vw;
        max-height: 40vw;
    }
}


@media (min-width: 769px) and (max-width: 1400px) {
    .life-photos {
        
        max-width: 50vw;
        max-height: 45vw;
    }
}

@media (min-width: 1401px) {
    .life-photos {
        
        max-width: 25vw;
        max-height: 20vw;
    }
}