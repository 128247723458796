.about-container {
    width: 100%;
    height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    padding: 60px;
}



.about-container p {
    text-align: justify;
}



.note {
    font-style: italic;
}