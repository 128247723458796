@media (max-width: 450px) {
    .large-text {
        font-size: 14px;
        line-height: 20px;
    }

    .image-group p {
        font-size: 12px;
    }
}

@media (min-width: 451px) and (max-width: 850px) {
    .large-text {
        font-size: 18px;
        line-height: 30px;
    }

    .image-group p {
        font-size: 14px;
    }
}

@media (min-width: 851px) and (max-width: 1500px) {
    .large-text {
        font-size: 20px;
        line-height: 30px;
    }

    .image-group p {
        font-size: 16px;
    }
}

@media (min-width: 1501px) {
    .large-text {
        font-size: 28px;
        line-height: 40px;
    }

    .image-group p {
        font-size: 24px;
    }
}