
.App {
  width: 100vw;
  height: 100vh;
  background-color: blue;

  display: flex;
}

.left-panel {
  height: 100vh;
  background-color: white;
  border-left: 10px solid black;
  border-right: 5px solid black;
  border-top: 10px solid black;
  border-bottom: 10px solid black;
  
}

.center-panel {
  height: 100vh;
  background-color: rgb(250, 250, 250);
  border-left: 5px solid black;
  border-right: 5px solid black;
  border-top: 10px solid black;
  border-bottom: 10px solid black;
  
}

.right-panel {
  height: 100vh;
  background-color: rgb(250, 250, 250);
  border-left: 5px solid black;
  border-right: 10px solid black;
  border-top: 10px solid black;
  border-bottom: 10px solid black;
}




@media (max-width: 1400px) {
  .left-panel-open {
    width: 150px;
  }
  .center-panel {
    display: none;
  }

  .right-panel-open {
    width: calc(100vw - 150px)
  }

  .right-panel-closed {
    width: calc(100vw - 20px);
  }

  .card-title {
    font-size: 18px !important;
  }
  .card-description {
    font-size: 16px !important;
  }
}


@media (min-width: 1401px) and (max-width: 4000px) {
  .left-panel-open {
    width: 300px;
  }
  .center-panel-open, .right-panel-open {
    width: calc(50vw - 150px);
  }
  

  .center-panel-closed, .right-panel-closed {
    width: calc(50vw - 10px);
  }
  
  .card-title {
    font-size: 20px !important;
  }
  .card-description {
    font-size: 18px !important;
  }
}