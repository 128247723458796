.education-container {
    padding: 10px;
    height: calc(100vh - 20px);
    text-align: center;
    overflow-y: auto;
}

.education-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}